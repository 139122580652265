//MEDIA QUERY MANAGER
@mixin breakpoint($breakpoint) {
    @if $breakpoint==xxxl {
      @media screen and (max-width: 1949.9px) {
        @content;
      }
    }
    @if $breakpoint==xxl {
      @media screen and (max-width: 1799.9px) {
        @content;
      }
    }
    @if $breakpoint==xl {
      @media screen and (max-width: 1499.9px) {
        @content;
      }
    }

    @if $breakpoint==xlg {
      @media screen and (max-width: 1399.9px) {
        @content;
      }
    }

    @if $breakpoint==lg {
      @media screen and (max-width: 1199.9px) {
        @content;
      }
    }

    @if $breakpoint==md {
      @media screen and (max-width: 991.9px) {
        @content;
      }
    }

    @if $breakpoint==sm {
      @media screen and (max-width: 767.9px) {
        @content;
      }
    }

    @if $breakpoint==xs {
      @media screen and (max-width: 575.9px) {
        @content;
      }
    }

    @if $breakpoint==xss {
      @media screen and (max-width: 373.9px) {
        @content;
      }
    }
  }
.b-promo {
  padding-top: 110px;
  padding-bottom: 104px;
  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
    padding-left: 100px;
    background: #eeeff5;
    border-radius: 20px;
    @include breakpoint(lg) {
      padding: 50px 60px 0;
    }
    @include breakpoint(md) {
      padding: 35px 35px 0;
    }
    @include breakpoint(sm) {
      padding: 31px 17px 0;
    }
    @include breakpoint(xs) {
      flex-direction: column;
      padding: 0;
    }
  }
  &__actions {
    display: flex;
    a:not(:last-child) {
      margin-right: 20px;
    }
  }
  &__container {
    @include breakpoint(sm) {
      padding: 0;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    padding-bottom: 255px;
    @include breakpoint(xs) {
      padding-bottom: 0;
      margin-bottom: 32px;
      padding: 31px 17px 0;
    }
  }
  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    &-wrap {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;
      @include breakpoint(lg) {
        max-width: 500px;
      }
      @include breakpoint(sm) {
        max-width: 430px;
      }
      @include breakpoint(xs) {
        position: static;
        max-width: 100%;
      }
    }
  }
  &__title {
    margin: 0px 0px 60px 0px;
    font-size: 45px;
    line-height: 60px;
    color: #000;
    br {
      display: none;
      @include breakpoint(sm) {
        display: block;
      }
    }
    @include breakpoint(md) {
      font-size: 36px;
      line-height: 52px;
    }
    @include breakpoint(sm) {
      margin-bottom: 32px;
    }
    @include breakpoint(xss) {
      font-size: 30px;
      line-height: 48px;
    }
  }
  @include breakpoint(lg) {
    padding-bottom: 30px;
  }
  @include breakpoint(md) {
    padding-top: 86px;
  }
}

.b-benefits {
  padding-top: 104px;
  padding-bottom: 30px;
  &__head {
    margin-bottom: 43px;
    @include breakpoint(md) {
      margin-bottom: 150px;
    }
  }
  &__content {
    display: flex;
  }
  &__inner {
    @include breakpoint(md) {
      padding-bottom: 280px;
    }
    @include breakpoint(sm) {
      padding-bottom: 180px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    margin-right: 66px;
    padding-top: 142px;
    padding-bottom: 154px;
    @include breakpoint(md) {
      padding-top: 0;
      margin-right: 0;
    }
  }
  &__item {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: #989898;
    cursor: pointer;
    transition: 0.2s;
    &.active {
      color: #000;
    }
    &:not(:last-child) {
      margin-bottom: 62px;
    }
    @include breakpoint(md) {
      cursor: default;
      &:not(:last-child) {
        margin-bottom: 42px;
      }
    }
  }
  &__sub {
    display: flex;
    align-items: center;
    margin: 0;
    opacity: 0;
    visibility: visible;
    transition: 0.2s;
    @include breakpoint(md) {
      display: none;
    }
  }
  &__sticky {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.active {
      .b-benefits {
        &__sub {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    @include breakpoint(md) {
      padding-top: 40px;
      position: absolute;
      right: 0;
      top: 0px;
      opacity: 0;
      visibility: hidden;
      transition: 0.2s;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__text {
    margin: 0;
    @include breakpoint(md) {
      font-size: 22px;
      line-height: 32px;
    }
  }
  &__image-container {
    top: 120px;
    position: -webkit-sticky;
    position: sticky;
    height: 100%;
    @include breakpoint(md) {
      top: 360px;
    }
    @media screen and (max-height: 750px) and (max-width: 767.9px) {
      top: 280px;
    }
  }
  &__images {
    margin-bottom: 22px;
  }
  &__icon {
    width: 21px;
    height: 21px;
    margin-right: 10px;
  }
  &__capture {
    font-size: 16px;
    line-height: 24px;
    color: #646464;
  }
  &__images {
    position: relative;
    width: 470px;
    height: 470px;
    background-color: #eeeff5;
    border-radius: 20px;
    @include breakpoint(sm) {
      width: 307px;
      height: 340px;
      overflow: hidden;
    }
    @include breakpoint(xss) {
      width: 270px;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__image-wrap {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    @include breakpoint(sm) {
      width: 100%;
      height: 100%;
    }
  }
  @include breakpoint(lg) {
    padding-top: 38px;
    scroll-margin-top: 50px;
  }
}

.b-steps {
  padding-top: 110px;
  padding-bottom: 60px;
  &__head {
    margin-bottom: 100px;
    @include breakpoint(md) {
      margin-bottom: 32px;
    }
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr 470px;
    gap: 30px;
    &--secondary {
      grid-template-columns: 470px 1fr;
    }
    @include breakpoint(md) {
      grid-template-columns: 1fr 1fr;
      gap: 22px;
    }
    @include breakpoint(sm) {
      grid-template-columns: 1fr;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
      @include breakpoint(md) {
        margin-bottom: 22px;
      }
    }
  }
  @include breakpoint(md) {
    padding-bottom: 37px;
  }
}

.b-steps-card {
  display: flex;
  min-height: 401px;
  width: 100%;
  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 100%;
    padding: 20px;
    background-color: #eeeff5;
    border-radius: 20px;
    @include breakpoint(sm) {
      padding-top: 107px;
    }
  }
  &__num {
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #6d698a;
    background-color: #fff;
    width: max-content;
    border-radius: 130px;
    padding: 5px 10px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    &-wrap {
      margin-bottom: 25px;
    }
  }
  &__text {
    max-width: 370px;
    margin: 0;
    text-align: center;
    font-size: 22px;
    line-height: 28px;
  }
  @include breakpoint(sm) {
    min-height: 364px;
  }
}

.b-roadmap {
  padding-top: 60px;
  padding-bottom: 215px;
  &__head {
    margin-bottom: 50px;
    @include breakpoint(sm) {
      margin-bottom: 54px;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    gap: 28px;
    margin-bottom: 70px;
    @include breakpoint(md) {
      gap: 15px;
    }
    @include breakpoint(sm) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 38px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__description {
    margin: 0px 0px 32px 0px;
    max-width: 780px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    color: #000;
    @include breakpoint(md) {
      font-size: 22px;
      line-height: 32px;
    }
    @include breakpoint(sm) {
      text-align: left;
      margin: 0;
    }
  }
  &__button {
    @include breakpoint(sm) {
      display: none;
    }
  }
  @include breakpoint(md) {
    padding-bottom: 100px;
  }
}

.b-roadmap-card {
  max-width: 370px;
  height: 340px;
  &__inner {
    position: relative;
    padding: 40px 20px;
    height: 100%;
    @include breakpoint(md) {
      padding: 55px 10px;
    }
  }
  &__checked {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: #eeeff5;
    border-radius: 50%;
    @include breakpoint(sm) {
      left: 7px;
    }
  }
  &__icon {
    width: 18px;
    height: 11px;
    stroke: #c2c2c2;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: 8px;
  }
  &__title {
    margin: 0;
    max-width: 300px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__description {
    margin: 0;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  &--first {
    .b-roadmap-card {
      &__image {
        background: linear-gradient(317.21deg, #5b95de -62.49%, #a6a1fc 100%);
      }
      &__image-icon {
        width: 37px;
        height: 53px;
      }
    }
  }
  &--second {
    .b-roadmap-card {
      &__image {
        background: linear-gradient(
          312.37deg,
          #ecbc6d -15.16%,
          #f8f0d5 126.64%
        );
      }
      &__image-icon {
        width: 42px;
        height: 54px;
      }
    }
  }
  &--third {
    .b-roadmap-card {
      &__image {
        background-color: #eeeff5;
      }
      &__image-icon {
        width: 44px;
        height: 34px;
      }
    }
  }
  &--checked {
    .b-roadmap-card {
      &__checked {
        background-color: #4c9c62;
      }
      &__icon {
        stroke: #fff;
      }
    }
  }

  @include breakpoint(md) {
    height: 380px;
  }
}

.b-about {
  padding-top: 70px;
  padding-bottom: 170px;
  background-color: #f8f0d5;
  &__head {
    margin-bottom: 68px;
    @include breakpoint(md) {
      margin-bottom: 55px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    @include breakpoint(sm) {
      flex-direction: column;
      gap: 0;
    }
  }
  &__info {
    max-width: 470px;
    padding-top: 76px;
    @include breakpoint(lg) {
      padding-top: 50px;
    }
    @include breakpoint(md) {
      padding: 0;
      max-width: 100%;
    }
  }
  &__text {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    @include breakpoint(md) {
      font-size: 22px;
      line-height: 32px;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    &-wrap {
      min-width: 450px;
      @include breakpoint(md) {
        min-width: 350px;
      }
      @include breakpoint(sm) {
        min-width: auto;
        margin-left: -17px;
      }
    }
  }
  @include breakpoint(md) {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}

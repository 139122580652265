html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  color: #000;
  height: 100%;
  text-rendering: optimizespeed;
  background-color: #fff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  position: relative;
}

body.locked {
  overflow: hidden;
}

body.locked:before {
  opacity: 1;
}

*, :before, :after {
  box-sizing: border-box;
  outline: none;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

::selection {
  color: #fff;
  background-color: #3e82e97e;
}

.b-wrapper {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.b-container {
  max-width: 1206px;
  margin: 0 auto;
  padding: 0 18px;
}

.b-container--small {
  max-width: 1007px;
}

.b-nav__list {
  display: flex;
}

.b-nav__link {
  color: #000;
  padding: 13px 16px;
  font-size: 16px;
  line-height: 24px;
}

.b-nav__link:hover {
  color: #2112a2;
}

@media screen and (max-width: 1199.9px) {
  .b-nav__link {
    padding: 13px 10px;
  }
}

@media screen and (max-width: 991.9px) {
  .b-nav__link {
    padding: 13px 5px;
    font-size: 14px;
    line-height: 20px;
  }
}

.b-button {
  color: #0d073f;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 17px;
  font-size: 14px;
  line-height: 20px;
  transition: all .2s;
}

@media screen and (max-width: 991.9px) {
  .b-button {
    padding: 8px 15px;
  }
}

.b-button--secondary {
  color: #000;
}

.b-button--secondary:hover {
  background-color: #eeeff5;
}

@media screen and (max-width: 575.9px) {
  .b-button--secondary {
    color: #fff;
    background-color: #2112a2;
  }
}

.b-button--dark {
  color: #fff;
  background-color: #2112a2;
}

.b-button--dark:hover {
  background-color: #3f30c0;
}

.b-button--tertiary {
  color: #fff;
  background-color: #000;
}

.b-button--form {
  color: #fff;
  width: 100px;
  background-color: #000;
  border-radius: 16px;
  flex-shrink: 0;
}

@media screen and (max-width: 767.9px) {
  .b-button--form {
    width: 70px;
  }
}

.b-button--large {
  padding: 12px 22px;
}

.b-logo {
  width: 136px;
  display: flex;
}

.b-logo__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.b-gradient {
  background: linear-gradient(90deg, #9747ff 6.73%, #ecbc6d 101.52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.b-gradient--secondary {
  background: linear-gradient(90deg, #ecbc6d 1.9%, #d85159 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.b-gradient--display {
  display: inline-block;
}

.b-title {
  color: #000;
  margin: 0;
  font-size: 36px;
  font-weight: 400;
  line-height: 58px;
  display: block;
}

@media screen and (max-width: 991.9px) {
  .b-title {
    font-size: 28px;
    line-height: 36px;
  }
}

.b-subtitle {
  color: #5f5d67;
  font-size: 16px;
  line-height: 24px;
}

.b-head__subtitle {
  margin-bottom: 22px;
  display: block;
}

.b-head--center {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.b-head--center .b-head__title {
  max-width: 670px;
}

.b-terms {
  opacity: 0;
  visibility: hidden;
  max-width: max-content;
  width: 100%;
  padding: 0 17px;
  transition: all .8s;
  position: fixed;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.b-terms__inner {
  background-color: #fff;
  border-radius: 8px;
  align-items: center;
  padding: 7px 10px;
  display: flex;
  box-shadow: 0 18px 20px #4747472b;
}

@media screen and (max-width: 373.9px) {
  .b-terms__inner {
    flex-direction: column;
  }
}

.b-terms__text {
  color: #000;
  margin: 0 10px 0 0;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (max-width: 373.9px) {
  .b-terms__text {
    text-align: center;
    margin: 0 0 10px;
  }

  .b-terms__button {
    width: 100%;
  }
}

.b-terms.visible {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.b-form__field {
  max-width: 570px;
  height: 73px;
  background-color: #eeeff5;
  border-radius: 16px;
  justify-content: space-between;
  margin-bottom: 36px;
  padding: 4px;
  display: flex;
}

@media screen and (max-width: 767.9px) {
  .b-form__field {
    max-width: 100%;
    margin-bottom: 25px;
  }
}

.b-form__icon {
  width: 49px;
  height: 15px;
}

@media screen and (max-width: 767.9px) {
  .b-form__icon {
    width: 40px;
    height: 12px;
  }
}

.b-input {
  width: 100%;
  background-color: #0000;
  border: none;
  padding: 16px 14px;
  font-size: 24px;
  line-height: 32px;
}

.b-input::placeholder {
  color: #8c8c90;
}

@media screen and (max-width: 767.9px) {
  .b-input {
    font-size: 16px;
    line-height: 24px;
  }
}

.b-checkbox {
  position: relative;
}

.b-checkbox__label {
  color: #5f5d67;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.b-checkbox__label a {
  color: #5b95de;
}

.b-checkbox__label a:hover {
  text-decoration: underline;
}

.b-checkbox__input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.b-checkbox__input ~ .b-checkbox__label {
  -webkit-user-select: none;
  user-select: none;
  padding-left: 27px;
  display: inline-block;
  position: relative;
}

.b-checkbox__input ~ .b-checkbox__label:before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #0000;
  border: 1px solid #c8c5d0;
  border-radius: 2px;
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
}

.b-checkbox__input ~ .b-checkbox__label:after {
  content: "";
  width: 25px;
  height: 25px;
  opacity: 0;
  background-image: url("checked.6ce790ca.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 7px;
  left: 4px;
}

.b-checkbox__input:checked ~ .b-checkbox__label:before {
  background: #2112a2;
  border-color: #2112a2;
}

.b-checkbox__input:checked ~ .b-checkbox__label:after {
  opacity: 1;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

[rel="js-tabs-target"] {
  display: none;
}

[rel="js-tabs-target"].active {
  display: block;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Bold.f642050e.woff2") format("woff2"), url("Roboto-Bold.6678d06c.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Medium.3f55cad1.woff2") format("woff2"), url("Roboto-Medium.26cfab3a.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.63f26da3.woff2") format("woff2"), url("Roboto-Regular.4cad464e.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Light.40314f3e.woff2") format("woff2"), url("Roboto-Light.a6465910.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

.b-header {
  z-index: 100;
  width: 100%;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 991.9px) {
  .b-header {
    top: 8px;
  }
}

@media screen and (max-width: 1199.9px) {
  .b-header__logo {
    width: 124px;
  }
}

@media screen and (max-width: 991.9px) {
  .b-header__nav {
    display: none;
  }
}

.b-header__inner {
  filter: drop-shadow(0 12px 12px #7e7e853d);
  z-index: 10;
  background-color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  display: flex;
}

@media screen and (max-width: 767.9px) {
  .b-header__inner {
    padding-left: 22px;
  }
}

.b-header__actions {
  display: flex;
}

.b-header__actions a:not(:last-child) {
  margin-right: 20px;
}

@media screen and (max-width: 991.9px) {
  .b-header__actions a:not(:last-child) {
    margin-right: 10px;
  }
}

@media screen and (max-width: 575.9px) {
  .b-header__button--desktop {
    display: none;
  }
}

.b-header.fixed {
  position: fixed;
  top: 0;
}

.b-header:before {
  content: "";
  width: calc(100% - 35px);
  height: 100%;
  z-index: -1;
  max-width: 1170px;
  background-color: #fff;
  position: absolute;
  bottom: calc(100% - 10px);
  left: 50%;
  transform: translateX(-50%);
}

.b-footer {
  border-top: 1px solid #000;
  padding-top: 44px;
  padding-bottom: 72px;
}

.b-footer__inner {
  display: flex;
}

@media screen and (max-width: 767.9px) {
  .b-footer__inner {
    flex-direction: column-reverse;
  }
}

.b-footer__info {
  flex-direction: column;
  margin-right: 157px;
  padding-top: 22px;
  display: flex;
}

@media screen and (max-width: 991.9px) {
  .b-footer__info {
    margin-right: 70px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-footer__info {
    align-items: center;
    margin: 56px 0 0;
    padding: 0;
  }
}

.b-footer__logo {
  margin-bottom: 22px;
}

@media screen and (max-width: 767.9px) {
  .b-footer__logo {
    margin-bottom: 29px;
  }
}

.b-footer__text {
  color: #5f5d67;
  font-size: 14px;
  line-height: 20px;
}

.b-footer__company {
  flex-direction: column;
  margin-bottom: 40px;
  display: flex;
}

@media screen and (max-width: 767.9px) {
  .b-footer__company {
    align-items: center;
    margin-bottom: 32px;
  }
}

.b-footer__list {
  display: flex;
}

@media screen and (max-width: 767.9px) {
  .b-footer__list {
    flex-direction: column;
    align-items: center;
  }
}

.b-footer__item:not(:last-child) {
  margin-right: 42px;
}

@media screen and (max-width: 767.9px) {
  .b-footer__item:not(:last-child) {
    text-align: center;
    margin: 0 0 20px;
  }
}

.b-footer__link {
  color: #5f5d67;
  font-size: 14px;
  line-height: 20px;
}

.b-footer__link:hover {
  text-decoration: underline;
}

.b-footer__title {
  max-width: max-content;
  margin: 0 0 22px;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

@media screen and (max-width: 373.9px) {
  .b-footer__title {
    font-size: 24px;
    line-height: 33px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-footer {
    padding-bottom: 58px;
  }
}

.b-promo {
  padding-top: 110px;
  padding-bottom: 104px;
}

.b-promo__inner {
  background: #eeeff5;
  border-radius: 20px;
  justify-content: space-between;
  padding-top: 70px;
  padding-left: 100px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1199.9px) {
  .b-promo__inner {
    padding: 50px 60px 0;
  }
}

@media screen and (max-width: 991.9px) {
  .b-promo__inner {
    padding: 35px 35px 0;
  }
}

@media screen and (max-width: 767.9px) {
  .b-promo__inner {
    padding: 31px 17px 0;
  }
}

@media screen and (max-width: 575.9px) {
  .b-promo__inner {
    flex-direction: column;
    padding: 0;
  }
}

.b-promo__actions {
  display: flex;
}

.b-promo__actions a:not(:last-child) {
  margin-right: 20px;
}

@media screen and (max-width: 767.9px) {
  .b-promo__container {
    padding: 0;
  }
}

.b-promo__content {
  z-index: 1;
  padding-bottom: 255px;
  position: relative;
}

@media screen and (max-width: 575.9px) {
  .b-promo__content {
    margin-bottom: 32px;
    padding: 31px 17px 0;
  }
}

.b-promo__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.b-promo__image-wrap {
  z-index: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 1199.9px) {
  .b-promo__image-wrap {
    max-width: 500px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-promo__image-wrap {
    max-width: 430px;
  }
}

@media screen and (max-width: 575.9px) {
  .b-promo__image-wrap {
    max-width: 100%;
    position: static;
  }
}

.b-promo__title {
  color: #000;
  margin: 0 0 60px;
  font-size: 45px;
  line-height: 60px;
}

.b-promo__title br {
  display: none;
}

@media screen and (max-width: 767.9px) {
  .b-promo__title br {
    display: block;
  }
}

@media screen and (max-width: 991.9px) {
  .b-promo__title {
    font-size: 36px;
    line-height: 52px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-promo__title {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 373.9px) {
  .b-promo__title {
    font-size: 30px;
    line-height: 48px;
  }
}

@media screen and (max-width: 1199.9px) {
  .b-promo {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 991.9px) {
  .b-promo {
    padding-top: 86px;
  }
}

.b-benefits {
  padding-top: 104px;
  padding-bottom: 30px;
}

.b-benefits__head {
  margin-bottom: 43px;
}

@media screen and (max-width: 991.9px) {
  .b-benefits__head {
    margin-bottom: 150px;
  }
}

.b-benefits__content {
  display: flex;
}

@media screen and (max-width: 991.9px) {
  .b-benefits__inner {
    padding-bottom: 280px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-benefits__inner {
    padding-bottom: 180px;
  }
}

.b-benefits__list {
  flex-direction: column;
  margin-right: 66px;
  padding-top: 142px;
  padding-bottom: 154px;
  display: flex;
}

@media screen and (max-width: 991.9px) {
  .b-benefits__list {
    margin-right: 0;
    padding-top: 0;
  }
}

.b-benefits__item {
  color: #989898;
  cursor: pointer;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  transition: all .2s;
}

.b-benefits__item.active {
  color: #000;
}

.b-benefits__item:not(:last-child) {
  margin-bottom: 62px;
}

@media screen and (max-width: 991.9px) {
  .b-benefits__item {
    cursor: default;
  }

  .b-benefits__item:not(:last-child) {
    margin-bottom: 42px;
  }
}

.b-benefits__sub {
  opacity: 0;
  visibility: visible;
  align-items: center;
  margin: 0;
  transition: all .2s;
  display: flex;
}

@media screen and (max-width: 991.9px) {
  .b-benefits__sub {
    display: none;
  }
}

.b-benefits__sticky {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.b-benefits__sticky.active .b-benefits__sub {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 991.9px) {
  .b-benefits__sticky {
    opacity: 0;
    visibility: hidden;
    padding-top: 40px;
    transition: all .2s;
    position: absolute;
    top: 0;
    right: 0;
  }

  .b-benefits__sticky.active {
    opacity: 1;
    visibility: visible;
  }
}

.b-benefits__text {
  margin: 0;
}

@media screen and (max-width: 991.9px) {
  .b-benefits__text {
    font-size: 22px;
    line-height: 32px;
  }
}

.b-benefits__image-container {
  height: 100%;
  position: sticky;
  top: 120px;
}

@media screen and (max-width: 991.9px) {
  .b-benefits__image-container {
    top: 360px;
  }
}

@media screen and (max-height: 750px) and (max-width: 767.9px) {
  .b-benefits__image-container {
    top: 280px;
  }
}

.b-benefits__images {
  margin-bottom: 22px;
}

.b-benefits__icon {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.b-benefits__capture {
  color: #646464;
  font-size: 16px;
  line-height: 24px;
}

.b-benefits__images {
  width: 470px;
  height: 470px;
  background-color: #eeeff5;
  border-radius: 20px;
  position: relative;
}

@media screen and (max-width: 767.9px) {
  .b-benefits__images {
    width: 307px;
    height: 340px;
    overflow: hidden;
  }
}

@media screen and (max-width: 373.9px) {
  .b-benefits__images {
    width: 270px;
  }
}

.b-benefits__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.b-benefits__image-wrap {
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
  position: absolute;
}

.b-benefits__image-wrap.active {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 767.9px) {
  .b-benefits__image-wrap {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1199.9px) {
  .b-benefits {
    padding-top: 38px;
    scroll-margin-top: 50px;
  }
}

.b-steps {
  padding-top: 110px;
  padding-bottom: 60px;
}

.b-steps__head {
  margin-bottom: 100px;
}

@media screen and (max-width: 991.9px) {
  .b-steps__head {
    margin-bottom: 32px;
  }
}

.b-steps__row {
  grid-template-columns: 1fr 470px;
  gap: 30px;
  display: grid;
}

.b-steps__row--secondary {
  grid-template-columns: 470px 1fr;
}

@media screen and (max-width: 991.9px) {
  .b-steps__row {
    grid-template-columns: 1fr 1fr;
    gap: 22px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-steps__row {
    grid-template-columns: 1fr;
  }
}

.b-steps__row:not(:last-child) {
  margin-bottom: 30px;
}

@media screen and (max-width: 991.9px) {
  .b-steps__row:not(:last-child) {
    margin-bottom: 22px;
  }

  .b-steps {
    padding-bottom: 37px;
  }
}

.b-steps-card {
  min-height: 401px;
  width: 100%;
  display: flex;
}

.b-steps-card__inner {
  background-color: #eeeff5;
  border-radius: 20px;
  flex: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 767.9px) {
  .b-steps-card__inner {
    padding-top: 107px;
  }
}

.b-steps-card__num {
  color: #6d698a;
  width: max-content;
  background-color: #fff;
  border-radius: 130px;
  padding: 5px 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.b-steps-card__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.b-steps-card__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.b-steps-card__image-wrap {
  margin-bottom: 25px;
}

.b-steps-card__text {
  max-width: 370px;
  text-align: center;
  margin: 0;
  font-size: 22px;
  line-height: 28px;
}

@media screen and (max-width: 767.9px) {
  .b-steps-card {
    min-height: 364px;
  }
}

.b-roadmap {
  padding-top: 60px;
  padding-bottom: 215px;
}

.b-roadmap__head {
  margin-bottom: 50px;
}

@media screen and (max-width: 767.9px) {
  .b-roadmap__head {
    margin-bottom: 54px;
  }
}

.b-roadmap__list {
  justify-content: space-between;
  gap: 28px;
  margin-bottom: 70px;
  display: flex;
}

@media screen and (max-width: 991.9px) {
  .b-roadmap__list {
    gap: 15px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-roadmap__list {
    flex-direction: column;
    align-items: center;
    margin-bottom: 38px;
  }
}

.b-roadmap__info {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.b-roadmap__description {
  max-width: 780px;
  text-align: center;
  color: #000;
  margin: 0 0 32px;
  font-size: 24px;
  line-height: 32px;
}

@media screen and (max-width: 991.9px) {
  .b-roadmap__description {
    font-size: 22px;
    line-height: 32px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-roadmap__description {
    text-align: left;
    margin: 0;
  }

  .b-roadmap__button {
    display: none;
  }
}

@media screen and (max-width: 991.9px) {
  .b-roadmap {
    padding-bottom: 100px;
  }
}

.b-roadmap-card {
  max-width: 370px;
  height: 340px;
}

.b-roadmap-card__inner {
  height: 100%;
  padding: 40px 20px;
  position: relative;
}

@media screen and (max-width: 991.9px) {
  .b-roadmap-card__inner {
    padding: 55px 10px;
  }
}

.b-roadmap-card__checked {
  width: 36px;
  height: 36px;
  background-color: #eeeff5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
}

@media screen and (max-width: 767.9px) {
  .b-roadmap-card__checked {
    left: 7px;
  }
}

.b-roadmap-card__icon {
  width: 18px;
  height: 11px;
  stroke: #c2c2c2;
}

.b-roadmap-card__content {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.b-roadmap-card__image {
  height: 100px;
  width: 100px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.b-roadmap-card__title {
  max-width: 300px;
  text-align: center;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.b-roadmap-card__description {
  text-align: center;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

.b-roadmap-card--first .b-roadmap-card__image {
  background: linear-gradient(317.21deg, #5b95de -62.49%, #a6a1fc 100%);
}

.b-roadmap-card--first .b-roadmap-card__image-icon {
  width: 37px;
  height: 53px;
}

.b-roadmap-card--second .b-roadmap-card__image {
  background: linear-gradient(312.37deg, #ecbc6d -15.16%, #f8f0d5 126.64%);
}

.b-roadmap-card--second .b-roadmap-card__image-icon {
  width: 42px;
  height: 54px;
}

.b-roadmap-card--third .b-roadmap-card__image {
  background-color: #eeeff5;
}

.b-roadmap-card--third .b-roadmap-card__image-icon {
  width: 44px;
  height: 34px;
}

.b-roadmap-card--checked .b-roadmap-card__checked {
  background-color: #4c9c62;
}

.b-roadmap-card--checked .b-roadmap-card__icon {
  stroke: #fff;
}

@media screen and (max-width: 991.9px) {
  .b-roadmap-card {
    height: 380px;
  }
}

.b-about {
  background-color: #f8f0d5;
  padding-top: 70px;
  padding-bottom: 170px;
}

.b-about__head {
  margin-bottom: 68px;
}

@media screen and (max-width: 991.9px) {
  .b-about__head {
    margin-bottom: 55px;
  }
}

.b-about__content {
  justify-content: space-between;
  gap: 50px;
  display: flex;
}

@media screen and (max-width: 767.9px) {
  .b-about__content {
    flex-direction: column;
    gap: 0;
  }
}

.b-about__info {
  max-width: 470px;
  padding-top: 76px;
}

@media screen and (max-width: 1199.9px) {
  .b-about__info {
    padding-top: 50px;
  }
}

@media screen and (max-width: 991.9px) {
  .b-about__info {
    max-width: 100%;
    padding: 0;
  }
}

.b-about__text {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
}

@media screen and (max-width: 991.9px) {
  .b-about__text {
    font-size: 22px;
    line-height: 32px;
  }
}

.b-about__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.b-about__image-wrap {
  min-width: 450px;
}

@media screen and (max-width: 991.9px) {
  .b-about__image-wrap {
    min-width: 350px;
  }
}

@media screen and (max-width: 767.9px) {
  .b-about__image-wrap {
    min-width: auto;
    margin-left: -17px;
  }
}

@media screen and (max-width: 991.9px) {
  .b-about {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

/*# sourceMappingURL=index.10efd583.css.map */

// ------------ VARIABLES --------------- //
$main-font: "Roboto";

// ------------ MAIN --------------- //

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  background-color: #fff;
  font-family: $main-font, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  height: 100%;
  position: relative;
  text-rendering: optimizeSpeed;
  &.locked {
    overflow: hidden;
    &::before {
      opacity: 1;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
a {
  text-decoration: none;
}

// =============== GENERAL =============== //

*::selection {
  background-color: #3e82e97e;
  color: #fff;
}

.b-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.b-container {
  max-width: 1206px;
  margin: 0px auto;
  padding: 0 18px;
  &--small {
    max-width: 1007px;
  }
  // @include breakpoint(md) {
  //   padding: 0 2rem;
  // }
  // @include breakpoint(sm) {
  //   padding: 0 1.5rem;
  // }
}

// =============== COMPONENTS =============== //

.b-nav {
  &__list {
    display: flex;
  }
  &__link {
    padding: 13px 16px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    &:hover {
      color: #2112a2;
    }
    @include breakpoint(lg) {
      padding: 13px 10px;
    }
    @include breakpoint(md) {
      font-size: 14px;
      line-height: 20px;
      padding: 13px 5px;
    }
  }
}

.b-button {
  padding: 8px 17px;
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
  color: #0d073f;
  border-radius: 6px;
  transition: 0.2s;
  border: none;
  cursor: pointer;
  @include breakpoint(md) {
    padding: 8px 15px;
  }

  &--secondary {
    color: #000;
    &:hover {
      background-color: #eeeff5;
    }
    @include breakpoint(xs) {
      color: #fff;
      background-color: #2112a2;
    }
  }
  &--dark {
    color: #fff;
    background-color: #2112a2;
    &:hover {
      background-color: #3f30c0;
    }
  }
  &--tertiary {
    background-color: #000;
    color: #fff;
  }
  &--form {
    flex-shrink: 0;
    background-color: #000;
    color: #fff;
    border-radius: 16px;
    width: 100px;
    @include breakpoint(sm) {
      width: 70px;
    }
  }
  &--large {
    padding: 12px 22px;
  }
}

.b-logo {
  display: flex;
  width: 136px;
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.b-gradient {
  background: linear-gradient(90deg, #9747ff 6.73%, #ecbc6d 101.52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &--secondary {
    background: linear-gradient(90deg, #ecbc6d 1.9%, #d85159 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &--display {
    display: inline-block;
  }
}

.b-title {
  display: block;
  margin: 0;
  font-size: 36px;
  line-height: 58px;
  font-weight: 400;
  color: #000;
  @include breakpoint(md) {
    font-size: 28px;
    line-height: 36px;
  }
}

.b-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: #5f5d67;
}

.b-head {
  &__subtitle {
    display: block;
    margin-bottom: 22px;
  }
  &--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .b-head {
      &__title {
        max-width: 670px;
      }
    }
  }
}

.b-terms {
  position: fixed;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.8s;
  max-width: max-content;
  width: 100%;
  padding: 0 17px;
  &__inner {
    display: flex;
    align-items: center;
    padding: 7px 10px;
    background-color: #fff;
    box-shadow: 0px 18px 20px rgba(71, 71, 71, 0.17);
    border-radius: 8px;
    @include breakpoint(xss) {
      flex-direction: column;
    }
  }
  &__text {
    margin: 0px 10px 0px 0px;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    @include breakpoint(xss) {
      text-align: center;
      margin: 0px 0px 10px 0px;
    }
  }
  &__button {
    @include breakpoint(xss) {
      width: 100%;
    }
  }

  &.visible {
    bottom: 20px;
    opacity: 1;
    visibility: visible;
  }
}

.b-form {
  &__field {
    display: flex;
    justify-content: space-between;
    max-width: 570px;
    height: 73px;
    padding: 4px;
    background-color: #eeeff5;
    border-radius: 16px;
    margin-bottom: 36px;
    @include breakpoint(sm) {
      max-width: 100%;
      margin-bottom: 25px;
    }
  }
  &__icon {
    width: 49px;
    height: 15px;
    @include breakpoint(sm) {
      width: 40px;
      height: 12px;
    }
  }
}

.b-input {
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  padding: 16px 14px;
  background-color: transparent;
  border: none;
  &::placeholder {
    color: #8c8c90;
  }
  @include breakpoint(sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

.b-checkbox {
  $par: &;
  position: relative;
  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5f5d67;
    cursor: pointer;
    a {
      color: #5b95de;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    & ~ .b-checkbox__label {
      display: inline-block;
      user-select: none;
      padding-left: 27px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 2px;
        display: inline-block;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 2px;
        border: 1px solid #c8c5d0;
        background-color: transparent;
      }
      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: 7px;
        background-image: url("../images/checked.svg");
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        opacity: 0;
      }
    }
    &:checked {
      & ~ .b-checkbox__label {
        &::before {
          border-color: #2112a2;
          background: #2112a2;
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
}

// ------------ OTHER --------------- //

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

// ------------ UTILS --------------- //

[rel="js-tabs-target"] {
  display: none;
  &.active {
    display: block;
  }
}

.b-footer {
  padding-top: 44px;
  padding-bottom: 72px;
  border-top: 1px solid #000;
  &__inner {
    display: flex;
    @include breakpoint(sm) {
      flex-direction: column-reverse;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    margin-right: 157px;
    @include breakpoint(md) {
      margin-right: 70px;
    }
    @include breakpoint(sm) {
      padding: 0;
      align-items: center;
      margin: 56px 0px 0px 0px;
    }
  }
  &__logo {
    margin-bottom: 22px;
    @include breakpoint(sm) {
      margin-bottom: 29px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
    color: #5f5d67;
  }
  &__company {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    @include breakpoint(sm) {
      align-items: center;
      margin-bottom: 32px;
    }
  }
  &__list {
    display: flex;
    @include breakpoint(sm) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__item {
    &:not(:last-child) {
      margin-right: 42px;
    }
    @include breakpoint(sm) {
      &:not(:last-child) {
        text-align: center;
        margin: 0px 0px 20px 0px;
      }
    }
  }
  &__link {
    font-size: 14px;
    line-height: 20px;
    color: #5f5d67;
    &:hover {
      text-decoration: underline;
    }
  }
  &__title {
    margin: 0px 0px 22px 0px;
    font-size: 28px;
    line-height: 36px;
    font-weight: 400;
    max-width: max-content;
    @include breakpoint(xss) {
      font-size: 24px;
      line-height: 33px;
    }
  }
  @include breakpoint(sm) {
    padding-bottom: 58px;
  }
}

.b-header {
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
  width: 100%;
  @include breakpoint(md) {
    top: 8px;
  }
  &__logo {
    @include breakpoint(lg) {
      width: 124px;
    }
  }
  &__nav {
    @include breakpoint(md) {
      display: none;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
    border-radius: 8px;
    filter: drop-shadow(0px 12px 12px rgba(126, 126, 133, 0.24));
    background-color: #fff;
    z-index: 10;
    @include breakpoint(sm) {
      padding-left: 22px;
    }
  }
  &__actions {
    display: flex;
    a:not(:last-child) {
      margin-right: 20px;
    }
    @include breakpoint(md) {
      a:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  &__button {
    &--desktop {
      @include breakpoint(xs) {
        display: none;
      }
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: calc(100% - 10px);
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 35px);
    height: 100%;
    background-color: #fff;
    z-index: -1;
    max-width: 1170px;
  }
}
